<template>
   <v-dialog v-model="show" max-width="550px" persistent>
      <v-layout align-start>
         <v-flex xs12>
            <v-snackbar
               v-model="snackbar"
               :color="snackColor"
               :right="true"
               :bottom="true"
               :timeout="4000"
            >
               {{ snackText }}
               <template #action="{ attrs }">
                  <v-btn
                     color="white"
                     text
                     v-bind="attrs"
                     @click="snackbar = false"
                  >
                     Cerrar
                  </v-btn>
               </template>
            </v-snackbar>

            <v-card elevation="0">
               <v-toolbar color="primary" dark>
                  <v-toolbar-title style="font-weight: 500">
                     <span v-if="$vuetify.breakpoint.smAndUp">
                        Informacón del becario - CICLO
                     </span>
                     <span v-else> Inf. becario - CICLO </span>
                     {{ periodoLectivo }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
               </v-toolbar>
               <v-form
                  ref="formBecario"
                  v-model="validBecario"
                  lazy-validation
                  mt-12
               >
                  <v-card>
                     <v-card-text class="text--primary">
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-center colorPrimary"
                                 style="font-size: 20px"
                              >
                                 {{ becaNombre.toUpperCase() }}
                              </v-flex>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-justify"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 16px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <br />
                                 <span style="color: black">
                                    Se ha detectado que posee una beca para el
                                    ciclo {{ periodoLectivo }}, por favor
                                    ingrese la información que se le solicita a
                                    continuación. En caso de no poseer DUI o
                                    NIT, deberá introducir en dichos campos los
                                    números de documento del responsable legal a
                                    su cargo respecto a pagos de la Universidad.
                                 </span>
                              </v-flex>
                           </v-layout>
                           <v-layout wrap>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="nombres"
                                    :rules="nombresRules"
                                    color="primary"
                                    label="Nombres"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="apellidos"
                                    :rules="apellidosRules"
                                    color="primary"
                                    label="Apellidos"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="dui"
                                    v-mask="'########-#'"
                                    :rules="duiRules"
                                    color="primary"
                                    label="DUI"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="nit"
                                    v-mask="'####-######-###-#'"
                                    :rules="nitRules"
                                    color="primary"
                                    label="NIT"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm12>
                                 <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    color="primary"
                                    label="Email"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm12>
                                 <v-text-field
                                    v-model="direccion"
                                    :rules="direccionRules"
                                    color="primary"
                                    label="Dirección"
                                 ></v-text-field>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary white--text"
                           :disabled="
                              !validBecario || !becaId > 0 || disableEnviar
                           "
                           @click.native="save"
                           >Enviar</v-btn
                        >
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-card>
         </v-flex>
      </v-layout>
   </v-dialog>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'BecarioForm',
   directives: {
      mask
   },
   props: {
      carnet: {
         type: String,
         default: null
      },
      periodoLectivo: {
         type: String,
         default: null
      },
      value: Boolean
   },
   data: () => ({
      // Variables
      disableEnviar: false,
      dialogBecario: false,
      dialogEliminar: false,
      pagination: {},
      result: [],
      becas: [],
      becaNombre: '',
      validBecario: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      becaId: '',
      nombres: '',
      apellidos: '',
      email: '',
      direccion: '',
      dui: '',
      nit: '',

      // Reglas de validacion de formularios
      nombresRules: [(v) => !!v || 'Ingrese sus nombres'],
      apellidosRules: [(v) => !!v || 'Ingrese sus apellidos'],
      duiRules: [
         (v) => !!v || 'Ingrese su DUI',
         (v) => (v && v.length == 10) || 'Termine de escribir su DUI'
      ],
      nitRules: [
         (v) => !!v || 'Ingrese su NIT',
         (v) => (v && v.length == 17) || 'Termine de escribir su NIT'
      ],
      emailRules: [
         (v) => !!v || 'Ingrese su dirección e-mail',
         (v) =>
            v === '' ||
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
               v
            ) ||
            'Formato incorrecto de e-mail'
      ],
      direccionRules: [(v) => !!v || 'Ingrese una dirección']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return 'Ingresa los siguientes datos'
      },
      show: {
         get() {
            return this.value
         },
         set(value) {
            this.$emit('input', value)
         }
      }
   },
   watch: {
      show(val) {
         if (val == true) {
            this.listarBecas()
         }
      }
   },
   created() {},
   methods: {
      close() {
         this.$emit('enviado', 'OK')
         this.show = false
      },
      // Popular el datatable
      listarBecas() {
         this.becas = []
         this.becaId = ''
         // Obteniendo becas
         axios
            .get(
               `api/Beca?Carnet=${this.carnet}&PeriodoLectivo=${this.periodoLectivo}`
            )
            .then((response) => {
               this.becas = response.data
               if (this.becas != []) {
                  if (this.becas[0].dui != null) {
                     this.clear()
                     this.close()
                  } else {
                     this.becaId = this.becas[0].becaId
                     this.becaNombre = this.becas[0].tipoBeca
                  }
               } else {
                  this.close()
               }
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Si no cargaron bien las becas en el datatable se puede resetear
      reset() {
         this.listarBecas()
      },
      save() {
         if (this.$refs.formBecario.validate()) {
            // Codigo para actualizar/editar
            this.disableEnviar = true
            axios
               .put('api/Beca/foralumno/' + this.becaId, {
                  nombres: this.nombres,
                  apellidos: this.apellidos,
                  email: this.email,
                  direccion: this.direccion,
                  dui: this.dui,
                  nit: this.nit
               })
               .then(() => {
                  this.snackText = 'Datos enviados exitosamente!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  setTimeout(() => {
                     this.disableEnviar = false
                     this.clear()
                     this.close()
                  }, 2500)
               })
               .catch((error) => {
                  console.log(error)
               })
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formBecario.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.nombres = ''
         this.apellidos = ''
         this.email = ''
         this.direccion = ''
         this.dui = ''
         this.nit = ''
      }
   }
}
</script>
