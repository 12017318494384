<template>
   <v-dialog v-model="show" max-width="450px" persistent>
      <v-layout align-start>
         <v-flex xs12>
            <v-snackbar
               v-model="snackbar"
               :color="snackColor"
               :right="true"
               :bottom="true"
               :timeout="4000"
            >
               {{ snackText }}
               <template #action="{ attrs }">
                  <v-btn
                     color="white"
                     text
                     v-bind="attrs"
                     @click="snackbar = false"
                  >
                     Cerrar
                  </v-btn>
               </template>
            </v-snackbar>

            <v-card elevation="0">
               <v-toolbar color="primary" dark>
                  <v-toolbar-title style="font-weight: 500">
                     <span> Ingresa tu número de DUI </span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
               </v-toolbar>
               <v-form ref="formDui" v-model="validDui" lazy-validation>
                  <v-card>
                     <v-card-text class="text--primary">
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex
                                 xs12
                                 class="font-weight-medium text-justify"
                                 :style="
                                    $vuetify.breakpoint.smAndUp
                                       ? 'font-size: 16px;'
                                       : 'font-size: 15px;'
                                 "
                              >
                                 <span style="color: black">
                                    Estimado alumno/a, según como establece el
                                    Código Tributario para poder realizar pagos
                                    mayores o iguales $200.00 es necesario tener
                                    registrado su número de DUI.
                                    <br />
                                    <br />
                                    En caso de no poseer DUI, deberá introducir
                                    en dicho campo el número de documento del
                                    responsable legal a su cargo respecto a
                                    pagos de la Universidad.
                                 </span>
                              </v-flex>
                           </v-layout>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <v-text-field
                                    v-model="dui"
                                    v-mask="'########-#'"
                                    :rules="duiRules"
                                    color="primary"
                                    label="DUI"
                                 ></v-text-field>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary white--text"
                           :disabled="!validDui"
                           @click.native="save"
                           >Enviar</v-btn
                        >
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-card>
         </v-flex>
      </v-layout>
   </v-dialog>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'DuiForm',
   directives: {
      mask
   },
   props: {
      carnet: {
         type: String,
         default: null
      },
      value: Boolean
   },
   data: () => ({
      // Variables
      validDui: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      dui: '',

      // Reglas de validacion de formularios
      duiRules: [
         (v) => !!v || 'Ingrese su DUI',
         (v) => (v && v.length == 10) || 'Termine de escribir su DUI'
      ]
   }),
   computed: {
      ...authComputed,
      show: {
         get() {
            return this.value
         },
         set(value) {
            this.$emit('input', value)
         }
      }
   },
   watch: {},
   created() {},
   methods: {
      close() {
         this.$emit('enviado', 'OK')
         this.show = false
      },
      save() {
         if (this.$refs.formDui.validate()) {
            // Codigo para actualizar/editar
            axios
               .put(
                  'api/Pago/ActualizarDui?carnet=' +
                     this.carnet +
                     '&dui=' +
                     this.dui
               )
               .then(() => {
                  this.snackText = 'Datos enviados exitosamente!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  setTimeout(() => {
                     this.clear()
                     this.close()
                  }, 2500)
               })
               .catch((error) => {
                  console.log(error)
               })
         }
      },
      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formDui.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.dui = ''
      }
   }
}
</script>
